import { DateTool } from "./date-tool.js";
import router from "../../router";
import saveAs from "file-saver";

class ReportPreviewTool {
  static previewReport(userName, reportURL, reportCreateTime) {
    const reportInfo = {
      userName,
      reportURL,
      reportCreateTime
    };
    let routeData = router.resolve({
      path: "/report",
      query: reportInfo
    });
    window.open(routeData.href, "_blank");
  }

  static previewBloodOxygenReport(reportId, token, uid) {
    const reportInfo = {
      reportId,
      token,
      uid
    };
    let routeData = router.resolve({
      path: "/blood-oxygen-report",
      query: reportInfo
    });
    window.open(routeData.href, "_blank");
  }

  static downloadReport(userName, reportURL, reportCreateTime) {
    const reportDownloadURL = reportURL;
    const reportDownloadName =
      userName + DateTool.timestampToStr(reportCreateTime, "M月D日 h：m");
    // saveAs(reportDownloadURL, reportDownloadName); //八戒睡眠前缀网址直接用window.open会跳转PDF预览页面，需要用saveAs工具下载
    // window.open(reportDownloadURL)
    window.location.href = reportDownloadURL
  }

  static downloadBloodOxygenReport(reportURL) {
    window.open(reportURL); //阿里云前缀网址直接用window.open可以下载
  }

  static downloadImage(userName, createTimeStr, pngURL) {
    const reportDownloadName = `${userName} ${createTimeStr}.png`;
    // saveAs(pngURL, reportDownloadName);
    // window.open(pngURL)
    window.location.href = pngURL
  }

  static downloadImageData(userName, createTimeStr, imageData) {
    const reportDownloadName = `${userName} ${createTimeStr}.png`;
    let aLink = document.createElement("a");
    aLink.style.display = "none";
    aLink.href = imageData;
    aLink.download = reportDownloadName;
    document.body.appendChild(aLink);
    aLink.click();
    document.body.removeChild(aLink);
  }
}

export { ReportPreviewTool };
