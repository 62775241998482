//向移动端发消息
const appHandler = {}
//告诉原生打开一个新页面
//params: 一个map
//path：消息发生的路径。
//origin： 消息发生的源。
//data: 消息的数据，不同消息不同数据。
appHandler.sendMsg = (params) => {
    if (window.Android) {
        window.Android?.sendMsg?.(JSON.stringify(params))
    } else {
        window.webkit?.messageHandlers.sendMsg?.postMessage(params)
    }
}

export default appHandler